import { QueryRunResult } from "@fscrypto/domain/query-run";
import { Client } from "@fscrypto/http";
import { createStore, useStore } from "@fscrypto/state-management";

export interface TablePreview {
  table: string;
  data?: QueryRunResult;
}

const tablePreviews = createStore<Record<string, TablePreview>>({});
const client = new Client();

async function addTablePreview(table: string) {
  const previews = tablePreviews.get();
  if (previews[table]) return;
  tablePreviews.set({ ...previews, [table]: { table } });
  const preview = await fetchTablePreview(table);
  tablePreviews.set({ ...previews, [table]: preview });
}

const removeTablePreview = (table: string) => {
  const previews = tablePreviews.get();
  delete previews[table];
  tablePreviews.set({ ...previews });
};

async function fetchTablePreview(table: string): Promise<{ table: string; data: QueryRunResult }> {
  try {
    const result = await client.get<QueryRunResult>(`/api/schemas/preview/${table}`);
    return { table: table, data: result };
  } catch (e) {
    throw new Error(`Error Fetching Table Preview: ${e}`);
  }
}

export const useTablePreviews = () => {
  const previews = useStore(tablePreviews);
  return {
    previews: Object.values(previews),
    addTablePreview,
    removeTablePreview,
  };
};
